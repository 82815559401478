import React from 'react';
import store from 'store';
import useAuth from '../components/use-auth';
import Layout from "../components/layout"
import SEO from "../components/seo"
import OrdersList from '../components/orders-list'
import { navigate } from 'gatsby';


const IndexPage = ({path}) => {
  const auth = useAuth();
  const settings = store.get('settings');
  const storeId = settings && settings.storeId;
  if (typeof window !== 'undefined' && !storeId) {
    navigate('/select-store');
  }
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Home" />
      { auth && <OrdersList /> }
    </Layout>
  )
}

export default IndexPage;
