import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import FlagIcon from '@material-ui/icons/Flag';
import { grey } from '@material-ui/core/colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import OrderItem from './order-item';
import { getTs, getShowFlag as getOrderShowFlag } from '../utils/orders';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: grey[100],
  },
  iconButton: {
    backgroundColor: grey[300],
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  cardContent: {
    paddingTop: 0,
  },
  cardContentHidden: {
    paddingTop: 0,
    paddingBottom: '0 !important',
  },
  icon: {
    paddingTop: '3px',
    fontSize: '0.9rem',
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  noOrders: {
    padding: theme.spacing(2),
    textAlign: 'center',
  }
}));

const getShowFlag = orders => orders.reduce((acc, o) => acc || getOrderShowFlag(o), false);  

const sortByTs = (a, b) => getTs(a) - getTs(b);

const OrdersColumn = props => {
  const { t } = useTranslation('ordersList');
  const { isListOpen, orders, title, onStatusChanged, onToggleList } = props;
  const classes = useStyles();
  const myRef = useRef(null)
  const scroll = () => window.scrollTo({
    top: myRef.current.offsetTop - 100,
    left: 0,
    behavior: 'smooth'
  });
  const handleToggle = () => {
    onToggleList();
    setTimeout(scroll, 500);
    setTimeout(scroll, 1000);
  }
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid item xs={12} md={3} ref={myRef}>
      <Card className={classes.card}>
        <CardHeader
          action={
            <IconButton aria-label="settings"  size="small" className={classes.iconButton} onClick={handleToggle}>
              { isListOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
            </IconButton>
          }
          title={
            <div>
              { getShowFlag(orders) && <FlagIcon className={classes.icon} color="secondary" /> }
              { isDesktop ? title : `${title} (${orders.length})` }
            </div>
          }
          titleTypographyProps={{ variant: 'button'}}
        />
        <CardContent className={isListOpen ? classes.cardContent : classes.cardContentHidden }>
          <Collapse in={isListOpen}>
            { !orders.length && <Typography className={classes.noOrders} variant="body2" color="textSecondary">{t('noOrders')}</Typography> }
            {orders.sort(sortByTs).map(order => (
              <OrderItem key={order.id} order={order} onStatusChanged={onStatusChanged} />
            ))}
          </Collapse>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrdersColumn;