import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FlagIcon from '@material-ui/icons/Flag';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import Email from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import Phone from '@material-ui/icons/Phone';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PlaceIcon from '@material-ui/icons/Place';
import CreateIcon from '@material-ui/icons/Create';

import DialogButton from './dialog-button';
import TransitionAlert from './transition-alert';
import OrderItemMenu from './order-item-menu';
import { ORDER_STATUS } from '../constants';
import { EP, callApi } from '../api';
import { languages } from './i18n';
import { getTs, getLocaleTs, getShowFlag } from '../utils/orders';

const useStyles = makeStyles((theme) => ({
  order: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    fontSize: '0.8rem',
  },
  cardContent: {
    paddingTop: 0,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#999',
    marginTop: '0.5em',
  },
  orderTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.3em',
    fontSize: '0.9rem',
  },
}));

const getTimeWaiting = (t, order, status) => {
  if ([ORDER_STATUS.delivered, ORDER_STATUS.cancelled].includes(order)) return;
  const now = new Date().getTime();
  const totalMinutes = Math.round((now - getTs(order, status))/(1000 * 60));
  const hours = Math.floor(totalMinutes / 60);          
  const minutes = totalMinutes % 60;
  return hours ? `(${hours} h ${minutes} min. ${t('ago')})` : `(${minutes} min. ${t('ago')})`;
}

const getSubheader = (t, order) => {
  return (<div>
    {t(`STATUS_SHORT_${order.status}`)}: {getLocaleTs(order)} {getTimeWaiting(t, order)}
    { order.status === ORDER_STATUS.carriedOut && <br /> }
    { order.status === ORDER_STATUS.carriedOut && (<span>
      {t('STATUS_SHORT_CUSTOMER_ARRIVED')}: {' '}
      {getLocaleTs(order, ORDER_STATUS.customerNotified)} {' '}
      {getTimeWaiting(t, order, ORDER_STATUS.customerNotified)}
    </span>)}
  </div>);
}

const OrderItem = ({ order, onStatusChanged }) => {
  const { t } = useTranslation('orderItem');
  const classes = useStyles();
  const [changeStatus, setChangeStatus ] = useState({ isUpdating: false, isError: false, error: null });

  const handleChangeStatus = async (orderId, status) => {
    setChangeStatus({ ...changeStatus, isUpdating: true, isError: false, error: null });
    try {
      const params = {
        orderId,
        body: { status },
      };
      const order = await callApi(EP.orders.patch, params);
      order && onStatusChanged(order);
    } catch(error) {
      console.log(error);
      const message = (error.response && error.response.data && error.response.data.message)
        || t('updateError'); 
      setChangeStatus({ ...changeStatus, isUpdating: false, isError: true, error: message });
    }
  }

  const orderLang = (languages.find(l => l.code === order.lang) || {}).label;
  const methodType = order && order.customerData && order.customerData.methodType;
  const customerData = order && order.customerData && (order.customerData.method || {})[methodType];

  return (
    <Card key={order.id} className={classes.order}>
      <CardHeader
        action={
          <OrderItemMenu 
            onChangeStatus={handleChangeStatus}
            onUnableToLocate={() => handleChangeStatus(order.id, ORDER_STATUS.customerNotified)}
            order={order}
          />
        }
        title={
          <div>
            { getShowFlag(order) && <FlagIcon className={classes.icon} color="secondary" /> }
            {order.id}
          </div>
        }
        subheader={getSubheader(t, order)}
        titleTypographyProps={{ variant: 'h6'}}
        subheaderTypographyProps={{ variant: 'caption'}}
      />
      <CardContent className={classes.cardContent}>
        <TransitionAlert severity="error" isOpen={changeStatus.isError}>{changeStatus.error}</TransitionAlert>
        {order.status === ORDER_STATUS.customerNotified && order.locationFailed && (
          <div className={classes.iconWrapper}>
            <FlagIcon className={classes.icon} />
            <Typography component="p" variant="body2" color="textPrimary">{t('unableToLocate')}</Typography>
          </div>
        )}
        <div className={classes.iconWrapper}>
          <PersonIcon className={classes.icon} />
          <Typography component="p" variant="body2">{order.name}</Typography>
        </div>
        <div className={classes.iconWrapper}>
          <Email className={classes.icon} />
          <Typography component="p" variant="body2">{order.email}</Typography>
        </div>
        {order.phone && (
          <div className={classes.iconWrapper}>
            <Phone className={classes.icon} />
            <Typography component="p" variant="body2">{order.phone}</Typography>
          </div>
        )}
        {(order.numItems) && (
          <div className={classes.iconWrapper}>
            <ShoppingBasketIcon className={classes.icon} />
            <Typography component="p" variant="body2">{t('items', {count: Number(order.numItems)})}</Typography>
          </div>
        )}
        {(order.storedLocation) && (
          <div className={classes.iconWrapper}>
            <PlaceIcon className={classes.icon} />
            <Typography component="p" variant="body2">{order.storedLocation}</Typography>
          </div>
        )}
        {orderLang && (
          <div className={classes.iconWrapper}>
            <LanguageIcon className={classes.icon} />
            <Typography component="p" variant="body2">{orderLang}</Typography>
          </div>
        )}
        {methodType === 'car' && (
          <div className={classes.iconWrapper}>
            <DirectionsCar className={classes.icon} />
            <Typography component="p" variant="body2">
              {customerData.carMake} {' '}
              {customerData.carModel} {' '}
              {customerData.carColour} {' '}
            {t('license')} #{customerData.plateNumber}
            </Typography>
          </div>
        )}

        {customerData && customerData.details && (
          <div className={classes.iconWrapper}>
            <CreateIcon className={classes.icon} />
            <Typography component="p" variant="body2">
              {t('customerDetails')}: {customerData.details}
            </Typography>
          </div>
        )}
        
        {order.status === ORDER_STATUS.customerArrived && (
          <DialogButton
            color="primary"
            variant="outlined"
            className={classes.button}
            title={t('carryOutTitle', { id: order.id })}
            description={t('carryOutBody')}
            onConfirm={e => handleChangeStatus(order.id, ORDER_STATUS.carriedOut)}
          >
            {t('actionCarryOut')}
          </DialogButton>
        )}
        {order.status === ORDER_STATUS.carriedOut && (
          <DialogButton
            color="primary"
            variant="outlined"
            className={classes.button}
            title={t('deliveryTitle', { id: order.id })}
            description={t('deliveryDescription', { id: order.id })}
            onConfirm={e => handleChangeStatus(order.id, ORDER_STATUS.delivered)}
          >
            {t('actionDelivered')}
          </DialogButton>
        )}
      </CardContent>
    </Card>
  );
}

export default OrderItem;
