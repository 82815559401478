import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';

import OrdersColumn from './orders-column';
import Loading from './loading';
import { useSearch } from './search-context';
import { useOrders } from './orders-context';
import { ORDER_STATUS } from '../constants';
import TransitionAlert from './transition-alert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  text: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  clearIconButton: {
    marginLeft: theme.spacing(1),
  },
  clearIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  }
}));

const getOrdersFiltered = (orders, searchTerm) => {
  const re = new RegExp(searchTerm.toLowerCase());
  return orders.filter(o => re.test(JSON.stringify(o).toLowerCase()));
}

const OrdersList = () => {
  const { t } = useTranslation('ordersList');
  const { t: tc } = useTranslation('common');
  const classes = useStyles();
  const [searchTerm, setSearch] = useSearch();
  const [{ error, isLoading, items: orders}, setOrders ] = useOrders();
  const ordersFiltered = getOrdersFiltered(orders, searchTerm);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [isListOpen, setIsListOpen] = useState([isDesktop, isDesktop, isDesktop, isDesktop]);
  useEffect(() => { setIsListOpen([isDesktop, isDesktop, isDesktop, isDesktop])}, [isDesktop]);
  
  const handleToggleList = id => setIsListOpen(
    isListOpen.map((_, i) => i === id ? !isListOpen[i] : false)
  );
  
  const onStatusChanged = order => {
    order && setOrders({ items: orders.map(o => o.id === order.id ? order : o) });
  }

  const STATUS_LIST = [
    { title: tc(`STATUS_${ORDER_STATUS.customerNotified}`), key: ORDER_STATUS.customerNotified },
    { title: tc(`STATUS_${ORDER_STATUS.customerArrived}`), key: ORDER_STATUS.customerArrived },
    { title: tc(`STATUS_${ORDER_STATUS.carriedOut}`), key: ORDER_STATUS.carriedOut },
  ];
  if (isLoading) return <Loading />
  if (error) return <TransitionAlert severity="error" isOpen>{error}</TransitionAlert>;
  return (
    <div className={classes.root}>
      { searchTerm && (
        <Typography variant='body2' className={classes.text}>
          {t('filteringBy')}: {searchTerm}
          <IconButton className={classes.clearIconButton} size="small" onClick={() => setSearch('')}>
            <CancelIcon color="primary" className={classes.clearIcon} />
          </IconButton>
        </Typography>
      )}
      <Grid container spacing={2}>
        { STATUS_LIST.map(({title, key}, i) => (
            <OrdersColumn
              key={key}
              title={title}
              isListOpen={isListOpen[i]}
              orders={ordersFiltered.filter(o => o.status === key)}
              onStatusChanged={onStatusChanged}
              onToggleList={() => handleToggleList(i)}
            />
        ))}
        <OrdersColumn
          title={tc('closed')}
          isListOpen={isListOpen[3]}
          orders={ordersFiltered.filter(o => [ORDER_STATUS.cancelled, ORDER_STATUS.delivered].includes(o.status))}
          onStatusChanged={onStatusChanged}
          onToggleList={() => handleToggleList(3)}
        />
      </Grid>
    </div>
  );
}

export default OrdersList;
