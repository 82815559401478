import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DialogButton from './dialog-button';
import { ORDER_STATUS } from '../constants';


const useStyles = makeStyles((theme) => ({
  moreButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

export default function OrderItemMenu({order, onChangeStatus}) {
  const { t } = useTranslation('orderItem');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if ([ORDER_STATUS.cancelled, ORDER_STATUS.delivered].includes(order.status)) return null;

  return (
    <div>
      <IconButton 
        className={classes.moreButton} 
        aria-controls="simple-menu" 
        aria-haspopup="true" 
        size="small" 
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      > 
        <MenuItem onClick={handleClose}>
          <DialogButton
            className="link"
            title={t('cancelTitle', { id: order.id })}
            description={t('cancelDescription', { id: order.id })}
            onConfirm={e => onChangeStatus(order.id, ORDER_STATUS.cancelled)}
          >
            {t('actionCancel')}
          </DialogButton>
        </MenuItem>
        { order.status === ORDER_STATUS.carriedOut && (
          <MenuItem onClick={handleClose}>
            <DialogButton
              title={t('locationFailedTitle', { id: order.id })}
              description={t('locationFailedDescription', { id: order.id })}
              onConfirm={e => onChangeStatus(order.id, ORDER_STATUS.customerNotified)}
            >
              {t('actionUnableToLocate')}
            </DialogButton>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
